<template>
  <div class="extration-bdd-frais">
    <!-- <b-button
      size="sm"
      class="button-export-style p-2 ml-2"
      v-b-modal.validate-statut-obligee-modal
      title="Modifier les statuts"
    >
      <font-awesome-icon icon="pen"
    /></b-button> -->
    <font-awesome-icon
      icon="edit"
      @click="$refs['validate-statut-obligee-modal' + type].show()"
    />
    <!-- Validation statue obligee Modal -->
    <b-modal
      :ref="'validate-statut-obligee-modal' + type"
      id="validate-statut-obligee-modal"
      no-close-on-backdrop
      :hide-footer="true"
      :title="'Modification globale de statut '"
      @hidden="hideModal('validate-statut-obligee-modal' + type)"
      modal-class="modal-validation-statut-frais"
    >
      <b-form-group
        id="fieldset-horizontal-type"
        label-cols-sm="5"
        label-cols-lg="3"
        content-cols-sm
        content-cols-lg="9"
        label="Statut: "
        label-for="type-modal"
      >
        <b-form-select
          id="fieldset-horizontal-type"
          v-model="statutSelected"
          :options="computedListType"
          required
          class="b-form-select-raduis"
        ></b-form-select>
      </b-form-group>
      <div class="form-modal-custom-style mt-2">
        <div class="messageError">
          <div v-if="error" class="error">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>
        </div>
        <div class="actionModel">
          <b-button
            class="button-valide-style"
            @click="handleUpdateStatutGlobale"
          >
            <span>
              Générer
              <div v-if="loading" class="loading ml-2">
                <div class="spinner-border" role="status"></div></div
            ></span>
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- END Validation Statue Installateur Modal -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    dataToUse: { required: true },
    type: { required: true }
  },
  data() {
    return {
      statutSelected: 'paye',
      error: null,
      loading: false,
      ListType: [
        { value: 'a paye', text: 'Facture à payer' },
        { value: 'paye', text: 'Facture payée' },
        { value: 'annulee', text: 'Facture annulée' }
      ]
    };
  },
  methods: {
    ...mapActions(['updateStatueObligeeGlobale']),
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    resetModal() {
      this.error = null;
      this.loading = false;
      this.statutSelected = 'paye';
    },
    async handleUpdateStatutGlobale() {
      this.loading = true;
      var bodyFormData = new FormData();
      bodyFormData.append('statut', this.statutSelected);
      let indexToUse = 0;

      for (let j = 0; j < this.dataToUse.length; j++) {
        for (let i = 0; i < this.dataToUse[j].lots.length; i++) {
          // for (let p = 0; p < this.dataToUse[j].lots[i].projects.length; p++) {
          if (
            this.dataToUse[j].lots[i].check == true &&
            this.dataToUse[j].lots[i][this.computedFilters] &&
            this.dataToUse[j].lots[i][this.computedFilters] != null
          ) {
            // if (p == 0) {
            bodyFormData.append(
              'ids[' + [indexToUse] + ']',
              this.dataToUse[j].lots[i][this.computedFields]
            );
            indexToUse = indexToUse + 1;
            // }
          }
          // }
        }
      }
      const response = await this.updateStatueObligeeGlobale(bodyFormData);
      if (response) {
        this.loading = false;
        this.hideModal('validate-statut-obligee-modal' + this.type);
        let text =
          '<div style="dispaly:flex"><div>Statut validé avec succès  : ' +
          response.message.success +
          '\n' +
          '\n' +
          '<div>Statut non modifié : ' +
          response.message.errors +
          '</div>';
        this.$alert('', text, 'success').then(() => {
          this.$emit('reloadData');
        });
      }
    }
  },
  computed: {
    ...mapGetters(['getUserData']),
    computedListType() {
      return this.ListType;
    },
    computedFilters() {
      switch (this.type) {
        case 'prime_statut':
          return 'prime_num_facture';
        case 'surprime_statut':
          return 'surprime_num_facture';
        case 'missing_prime_statut':
          return 'missing_prime_num_facture';
        case 'missing_prime_av_statut':
          return 'missing_prime_av_num_facture';
        case 'commission_statut':
          return 'commission_num_facture';
        default:
          return [
            'prime_num_facture',
            'surprime_num_facture',
            'missing_prime_num_facture',
            'missing_prime_av_num_facture',
            'commission_num_facture'
          ];
      }
    },
    computedFields() {
      switch (this.type) {
        case 'prime_statut':
          return 'prime_id';
        case 'surprime_statut':
          return 'surprime_id';
        case 'missing_prime_statut':
          return 'missing_prime_id';
        case 'missing_prime_av_statut':
          return 'missing_prime_av_id';
        case 'commission_statut':
          return 'commission_id';
        default:
          return [
            'prime_id',
            'surprime_id',
            'missing_prime_id',
            'missing_prime_av_id',
            'commission_id'
          ];
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
<style lang="scss">
.align-item-validate-statut-obligee-modal {
  display: flex;
  .form-group {
    width: 50%;
  }
}
.modal-validation-statut-frais {
  .d-block,
  #fieldset-horizontal,
  #fieldset-horizontal-type {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    text-align: start;
    color: #2a2a2a;
    margin-left: 3%;
    margin-top: 8px;
    margin-bottom: 1px;
  }
  .form-modal-custom-style {
    text-align: center;
  }
}
</style>
